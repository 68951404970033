import * as React from "react"
import BlockParser from "../components/block-parser";
import Layout from "../components/layout/layout";
import {IBlock} from "./page";
import TitleImageBlock from "../components/blocks/title-image-block/title-image-block";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";

interface IProjectData {
    title: string,
    pageContext: {
        blocks: [IBlock],
        isFrontPage: boolean,
        props: {
            address: string,
            location: string,
            title: string,
            mainImage: {
                localFile: FileNode
            }
        }
        title: string
    }
}

const Project = (project: IProjectData) => {
    return (
        <Layout isFrontPage={project.pageContext.isFrontPage}>
            <TitleImageBlock title={project.pageContext.props.title} subtitle={project.pageContext.props.location}
                             image={project.pageContext.props.mainImage}/>
            <BlockParser blocks={project.pageContext.blocks}/>
        </Layout>
    )
}

export default Project
